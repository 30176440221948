import React from "react";
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import css from './styles.module.css';
import { useLocation, Link } from "react-router-dom";
import { FormattedMessage, useIntl } from 'react-intl';
import { Outlet } from "react-router-dom";
import { version } from "../../misc";

export default function UnAuthLayout({ sideImage, children }) {
    const contentWidth = sideImage ? 6 : 12;
    const intl = useIntl()
    const location = useLocation()

    const handleLanguageToggle = (newLang) => {
        //Don't use Navigate() because it's outside the router, figure out some other time
        const path = location.pathname + '/' //ensure there's always a trailing slash
        const startPos = path.substring(0, 4) === `/${intl.locale}/` ? 3 : 0
        window.location.href = `/${newLang}` + location.pathname.substr(startPos) + location.search
    }

    return [
        <div key='languageToggle' style={{ position: 'absolute', top: '10px', right: '10px' }}>
            <Button onClick={() => { handleLanguageToggle('fr') }} className={intl.locale === 'fr' ? css.languageActive : css.languageInactive} style={{ marginRight: '20px' }}>FR</Button>
            <Button onClick={() => { handleLanguageToggle('en') }} className={intl.locale === 'en' ? css.languageActive : css.languageInactive}>EN</Button>
        </div>,
        <Grid key='content' container style={{ flexDirection: 'column' }} className={css.background}>
            <Grid container item xs={11} sm={9} md={11} lg={11} className={css.contentBox}>
                <Grid item xs={12} sm={12} md={contentWidth} className={css.contentPadding}>
                    {children}
                </Grid>
                <Hidden smDown>
                    <Grid item xs={6} className={css.sidebarImage} style={{ backgroundImage: `url(${sideImage})` }}></Grid>
                </Hidden>
            </Grid>
            <Grid item xs={12} className={css.copyright}>
                ©2020 Simply For Life.&nbsp;
                <FormattedMessage
                    id="app.allRightsReserved"
                    defaultMessage="All rights reserved."
                />&nbsp;
                <FormattedMessage
                    id="app.version"
                    defaultMessage="Version"
                />: {version}<br />
                <Link to={`/${intl.locale}/terms-of-use`}>
                    <FormattedMessage
                        id="app.termsOfUse"
                        defaultMessage="Terms Of Use"
                    />
                </Link>
                &nbsp;|&nbsp;
                <Link to={`/${intl.locale}/privacy-policy`}>
                    <FormattedMessage
                        id="app.privacyPolicy"
                        defaultMessage="Privacy Policy"
                    />
                </Link>
            </Grid>
        </Grid >
    ]
}