import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import LoginIcon from '@material-ui/icons/ExitToApp';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import ColoredButton from 'sfl-components/Buttons/Button';

import logo from "assets/images/sfl-logo.svg";

import { setSessionToken } from 'store/session';
import { useSFLApi, get } from 'api';
import { toast } from 'react-toastify';

import { FormattedMessage, useIntl } from 'react-intl';

import GooglePlayStore from 'assets/images/google_play_store.png'
import AppleAppStore from 'assets/images/apple_app_store.png'
import { setLanguage } from 'store/appSettings';
import sideImage from "assets/images/loginSideCentered.jpg";
import Layout from '../Layout';


export default function SignInForm() {
	const [state, setState] = useState({});
	const { username = '', password = '', showPassword = false } = state;
	const dispatch = useDispatch();
	const { post } = useSFLApi();
	const navigate = useNavigate();
	const intl = useIntl();

	const handleChange = (name, value) => {
		setState(currentState => ({ ...currentState, [name]: value }))
	}

	const preventDefault = (event) => {
		event.preventDefault();
	}

	const submitLogin = async () => {
		try {
			const data = await post('sessions', state)

			//Manually set token to avoid dispatch to set session UUID for future API calls
			const profileData = await get(data.session.uuid, 'en', 'profile')

			dispatch(setLanguage(profileData.profile.language || 'en'))
			dispatch(setSessionToken(data.session.uuid, data.session.user_type, data.session.user_uuid));
			navigate('/')
		}
		catch (error) {
			console.log('error', error)
			toast.error(intl.formatMessage({ id: "login.loginFailed", defaultMessage: "Login Failed" }), { toastId: 'SignInInvalidLogin' })
		}
	}

	return <Layout sideImage={sideImage}>
		<form onSubmit={preventDefault} action="/">
			<img src={logo} alt='Simply For Life' style={{ width: '100%' }} />
			<Grid item xs={12} style={{ padding: '1rem 0', marginTop: '20px' }}>
				<TextField
					id="username"
					label={intl.formatMessage({ id: "app.username", defaultMessage: "Username" })}
					value={username}
					fullWidth
					variant="outlined"
					onChange={event => handleChange('username', event.target.value)}
				/>
			</Grid>
			<Grid item xs={12} style={{ padding: '1rem 0' }}>
				<FormControl fullWidth variant="outlined">
					<InputLabel htmlFor="password">
						<FormattedMessage id="app.password" defaultMessage="Password" />
					</InputLabel>
					<OutlinedInput
						id="password"
						label={intl.formatMessage({ id: "app.password", defaultMessage: "Password" })}
						type={showPassword ? 'text' : 'password'}
						value={password}
						onChange={event => handleChange('password', event.target.value)}
						endAdornment={
							<InputAdornment position="end">
								<IconButton
									aria-label="toggle password visibility"
									onClick={() => handleChange('showPassword', !showPassword)}
									onMouseDown={preventDefault}
								>
									{showPassword ? <Visibility /> : <VisibilityOff />}
								</IconButton>
							</InputAdornment>
						}
					/>
				</FormControl>
			</Grid>
			<Grid item xs={12} style={{ textAlign: 'center', paddingTop: '1rem' }}>
				<ColoredButton color='purple' type="submit" style={{ width: '100%', marginBottom: '2rem' }} startIcon={<LoginIcon />} onClick={submitLogin}>
					<FormattedMessage
						id="app.signIn"
						defaultMessage="Sign In"
					/>
				</ColoredButton>
				<Button onClick={() => navigate('forgot-login')}>
					<FormattedMessage
						id="login.forgotYourLogin"
						defaultMessage="Forgot Your Login?"
					/>
				</Button>
				<hr style={{ margin: '20px 0 ' }} />
				<Button variant="contained" href='http://simplyforlife.com/#consultant' target="_blank">
					<FormattedMessage
						id="login.needAnAccount"
						defaultMessage="Need an account? Sign up today!"
					/>
				</Button>
				<br />
				<br />
				<br />
				<a href='https://play.google.com/store/apps/details?id=com.simplyforlife.app' style={{ margin: '20px' }}>
					<img src={GooglePlayStore} style={{ width: '150px' }} />
				</a>
				<a href='https://apps.apple.com/ca/app/simply-for-life/id1594421277' style={{ margin: '20px' }}>
					<img src={AppleAppStore} style={{ width: '150px' }} />
				</a>
			</Grid>
		</form>
	</Layout>
}